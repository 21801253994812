export function sinceDate(date: Date | string | number) {
  const now = new Date();
  const diffInSeconds = Math.floor(
    (now.getTime() - new Date(date).getTime()) / 1000,
  );

  const days = Math.floor(diffInSeconds / (3600 * 24));
  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;

  const hours = Math.floor(diffInSeconds / 3600);
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;

  const minutes = Math.floor(diffInSeconds / 60);
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;

  return `Just now`;
}

export function untillDate(date: Date | string | number) {
  const now = new Date();
  const diffInSeconds = Math.floor(
    (new Date(date).getTime() - now.getTime()) / 1000,
  );

  if (diffInSeconds <= 0) return "expired";

  const days = Math.floor(diffInSeconds / (3600 * 24));
  if (days > 0) return `${days} day${days > 1 ? "s" : ""}`;

  const hours = Math.floor(diffInSeconds / 3600);
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""}`;

  const minutes = Math.floor(diffInSeconds / 60);
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""}`;

  return null;
}

export function formatNiceDate(date: Date | string) {
  if (!date) return;

  date = new Date(date);

  const day = date.getDate();
  const ordinalSuffix = (day: number) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const dayWithSuffix = day + ordinalSuffix(day);
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return `${dayWithSuffix} ${formattedDate}`;
}
